import * as React from 'react';
import * as navStyles from './nav.module.css';
import { Link } from 'gatsby';

const NAV_HEIGHT = 36;

function Nav({ refs = [] }) {
  // TODO: Test if this works when navigating straight to a #someId
  const [currentPage, setCurrentPage] = React.useState(0);

  React.useEffect(
    () => {
      function handleScroll() {
        if (refs.every((r) => r.current)) {
          let page = 0;
          for (let i = refs.length - 1; i >= 0; i--) {
            if (refs[i].current.getBoundingClientRect().y < NAV_HEIGHT) {
              page = i;
              break;
            }
          }
          setCurrentPage(page);
        }
      }
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    },
    refs.map((r) => r.current)
  );

  return (
    <>
      <div className={navStyles.container}>
        <Link
          to="/"
          id="home-link"
          className={`${navStyles.item} ${
            currentPage === 0 ? navStyles.selectedHome : ''
          }`}
        >
          Home
        </Link>
        <Link
          to="/#showcase"
          id="showcase-link"
          className={`${navStyles.item} ${
            currentPage === 1 ? navStyles.selectedShowcase : ''
          }`}
        >
          Showcase
        </Link>
        <Link
          to="/#education"
          id="education-link"
          className={`${navStyles.item} ${
            currentPage === 2 ? navStyles.selectedBackground : ''
          }`}
        >
          Education
        </Link>
        <Link
          to="/#contact"
          id="contact-link"
          className={`${navStyles.item} ${
            currentPage === 3 ? navStyles.selectedContact : ''
          }`}
        >
          Contact
        </Link>
      </div>
      <div className={navStyles.placeholder} />
    </>
  );
}

export default Nav;
